//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AiToolsMixin from '@/components/mixins/ai-tools.mixin.js';

export default {
  name: 'StudioAi',
  mixins: [AiToolsMixin],
  components: {
    PageMeta: () => import('@motionelements/core/src/components/ThePageMeta.vue'),
  },
  computed: {
    pageTitle() {
      return this.$t('header.studio_ai');
    },
  },
};
